import React from 'react';
import Page from '../components/Page';

interface Location {
  pathname: string;
}

interface RawPageProps {
  location: Location;
  pageContext: object;
  pathContext: object;
}

export default (props: RawPageProps) => {
  return <Page {...props.pageContext} pagePath={props.location.pathname} />;
};
